import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import Nav from './Nav';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  header: {
    background:
      'linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url(/graphics/hero.jpg?v=2)',
    backgroundSize: 'cover, cover',
    backgroundPosition: 'center, center',
    [theme.breakpoints.up('md')]: {
      backgroundSize: 'cover, cover',
    },
  },
  content: {
    backgroundColor: theme.palette.background.default,
  },
}));

const Layout = ({ container, children }) => {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />

      <header className={classes.header}>
        <Nav />
      </header>

      <Box pt={6} pb={12} className={classes.content}>
        <Container maxWidth={container}>{children}</Container>
      </Box>

      <Footer />
    </>
  );
};

Layout.propTypes = {
  container: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  container: 'lg',
};

export const withLayout = (Component, layoutProps = {}) => (props) => (
  <Layout {...layoutProps}>
    <Component {...props} />
  </Layout>
);

export default Layout;
