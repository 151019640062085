import React from 'react';
import { Link } from 'gatsby';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MuiLink from '@material-ui/core/Link';

const Contact = () => (
  <>
    <Box mb={2}>
      <Typography variant="body1">
        Feel free to reach out with any questions or comments.
      </Typography>
    </Box>

    <Box mb={4}>
      <Button
        disableElevation
        component={Link}
        to="/contact"
        variant="contained"
        color="secondary"
      >
        Send a message
      </Button>
    </Box>

    <Box mb={2}>
      <Typography variant="body1">
        If you would like specific confidential communication please write to
        our elders:
      </Typography>
    </Box>

    <Box mb={4}>
      <Typography variant="body1">
        <MuiLink href="mailto:elders@rlcf.church">elders@rlcf.church</MuiLink>
      </Typography>
    </Box>
  </>
);

export default Contact;
