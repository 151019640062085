import React from 'react';
import { Link } from 'gatsby';
import { Typography, Box, Divider, Link as MuiLink } from '@material-ui/core';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Prose from '../components/Prose';
import Contact from '../components/Contact';

const Page = () => (
  <Layout>
    <SEO title="About Us" />
    <Box textAlign="center" mb={6}>
      <Typography variant="h1">About</Typography>
    </Box>

    <Box mb={8}>
      <Divider />
    </Box>

    <Box mt={3} mb={3}>
      <Typography id="what-we-believe" variant="h3">
        What We Believe
      </Typography>
    </Box>
    <Prose>
      <ul>
        <li>
          That the Bible (66 books) is the inspired and infallible Word of God,
          the sole and sufficient guide for our life on earth.
        </li>
        <li>
          That there is one God eternally existent in three Persons: Father, Son
          and Holy Spirit.
        </li>
        <li>
          In the Deity of our Lord Jesus Christ, His virgin birth, His humanity,
          His perfectly sinless life, His substitutionary death as an atonement
          for our sins, His bodily resurrection, His ascension to the Father,
          and His personal return to the earth for His saints.
        </li>
        <li>
          That all human beings are dead in sin and utterly lost and that the
          only way their sins can be forgiven is through repentance and through
          faith in the death and resurrection of our Lord Jesus Christ.
        </li>
        <li>
          In the regenerating work of the Holy Spirit, whereby a person is born
          again to be a child of God.
        </li>
        <li>
          That justification is by faith in Christ alone, the evidence of this
          being good works that glorify God.
        </li>
        <li>
          In baptism in water, by immersion, after regeneration, in the Name of
          the Father, the Son the Lord Jesus Christ, and the Holy Spirit.
        </li>
        <li>
          In the necessity of being filled with the Holy Spirit continually in
          order to have power to be witnesses for Christ — by life and by word.
        </li>
        <li>
          In the resurrection of the righteous to eternal life and the
          resurrection of the unrighteous to eternal damnation.
        </li>
      </ul>
      For a fuller statement of what we believe, please read the following
      article:{' '}
      <MuiLink href="https://www.cfcindia.com/article/the-truth-that-we-believe">
        The Truth That We Believe
      </MuiLink>{' '}
      by Zac Poonen.
    </Prose>

    <Box mt={3} mb={3}>
      <Typography id="leadership" variant="h3">
        Leadership
      </Typography>
    </Box>
    <Prose>
      RLCF is led by 4 elders —{' '}
      <MuiLink component={Link} to="/speakers/santosh-poonen">
        Santosh Poonen
      </MuiLink>
      ,{' '}
      <MuiLink component={Link} to="/speakers/ajay-chakravarthy">
        Ajay Chakravarthy
      </MuiLink>
      ,{' '}
      <MuiLink component={Link} to="/speakers/david-bertsch">
        David Bertsch
      </MuiLink>
      , and{' '}
      <MuiLink component={Link} to="/speakers/olu-talabi">
        Olu Talabi
      </MuiLink>
      . All of our elders support themselves and their families through secular
      jobs, and none of them is paid by the church. This is not because we feel
      that such financial support is un-Scriptural, but because we desire to
      maintain a pure testimony in the matter of finances amid Christendom that
      is full of the opposite.
    </Prose>

    <Box mt={3} mb={3}>
      <Typography id="purpose-and-affiliation" variant="h3">
        Our Purpose and Affiliation
      </Typography>
    </Box>
    <Prose>
      Our purpose as a church is to be disciples of the Lord Jesus Christ first
      ourselves, and then to make disciples in obedience to the Lord’s
      commission in Matthew 28:18-20, emphasizing the new birth, holiness of the
      inner life, mutual love, moral purity, financial integrity, and sharing
      God’s truth with others – founding every aspect of our lives on God’s Word
      alone. RLCF is not connected denominationally with any group or church
      anywhere. We are evangelical in our faith, and gladly receive all who are
      members of the worldwide Body of Christ.
    </Prose>

    <Box mt={3} mb={3}>
      <Typography id="our-meetings" variant="h3">
        Our Meetings
      </Typography>
    </Box>
    <Prose>
      <p>
        We believe in the importance of meeting regularly to encourage one
        another (Hebrews 3:13; Hebrews 10:26). While this happens freely
        throughout the week in our homes as led by the Holy Spirit, we have also
        set aside specific times — including Sunday mornings — to meet together
        as a local expression of the Body of Christ.
      </p>
      <p>
        While we do not follow any formal order of service during our meetings,
        we believe that all things must be done properly and in an orderly
        manner (I Corinthians 14:40). Within this context, we encourage all who
        are truly filled with the Holy Spirit to earnestly desire to prophesy (I
        Corinthians 14:1-4,23-33). We also believe and teach the practical
        expression of God’s headship order through uncovering/covering of our
        heads during times of prayer and prophecy (I Corinthians 11:2-5). We do
        not enforce this on everyone present in our meetings, since obedience to
        all of God’s commands must come from each individual’s faith (Romans
        14:23b). If you would like to study this topic further, please refer to
        these 2 articles:{' '}
        <MuiLink href="https://www.cfcindia.com/article/headcovering-for-women">
          Head-covering for Women
        </MuiLink>{' '}
        (by Zac Poonen) and{' '}
        <MuiLink href="https://www.cfcindia.com/article/head-coverings-two-spiritual-statements">
          Head Coverings – Two Spiritual Statements
        </MuiLink>{' '}
        (by Sandeep Poonen).
      </p>
      <p>
        We usually meet for lunch after our Sunday meetings to enhance building
        of fellowship among us. All visitors to our church services are welcome
        to freely join us for lunch as well.
      </p>
    </Prose>

    <Box mt={3} mb={3}>
      <Typography id="our-financial-policy" variant="h3">
        Our Financial Policy
      </Typography>
    </Box>
    <Box mb={3}>
      <Prose>
        <p>
          A genuine work of God may use money, but it is never dependent on
          money. It is dependent only on the power of the Holy Spirit. Where any
          work is dependent on money, it is not a genuine work of God. Jesus’
          ministry was dependent only on the Holy Spirit. He did accept gifts of
          money from His followers for His work (Luke 8:2-3). But He never asked
          anyone at any time for money and He never made His financial needs
          known to anyone but His Father. This is the way that any ministry that
          honors God will work even today. Jesus was the first physical “Body of
          Christ” on earth. Today we in the church are called the spiritual
          “Body of Christ”. And so we seek to follow the example that Jesus
          Himself set for us in the matter of finances, during His earthly life.
          Please read this article by Zac Poonen on{' '}
          <MuiLink href="https://cfcindia.com/wftw/the-financial-policy-of-jesus-and-the-apostles">
            The Financial Policy of Jesus and the Apostles
          </MuiLink>
          .
        </p>{' '}
        <p>
          We do not ask anyone for money for our needs, and we do not send out
          any “prayer letters” hinting about our needs either. We do not take
          offerings by passing around a bag/plate in our church meetings. We
          only place a freewill “offering box” in our church auditorium for
          those who wish to give — cheerfully (II Corinthians 9:7); faithfully
          (I Corinthians 16:2); secretly (Matthew 6:3-4); and in reconciliation
          with each other (Matthew 5:23-24).
        </p>
        <p>
          All money that is received as freewill offerings or as voluntary
          contributions, goes directly to the work of spreading the gospel
          through conferences, literature and media. None of it goes to any
          individual, since we do not have any paid staff. We offer registration
          and food freely to all who attend our general conferences. Our elders
          support themselves with earthly jobs (like the apostle Paul did), and
          none of them is supported financially by the church. Our books,
          articles, and audio and video messages on the internet are also
          available for anyone in the world to read/hear/view freely. We have
          received everything freely from the Lord and we give it all out to
          others freely.
        </p>
        <p>
          All of this ministry certainly requires money, and we are not
          “financed” by any rich human being. We seek the kingdom of God first,
          and God supplies ALL our needs always. We seek to follow the example
          of Jesus Himself — the first “Body of Christ” on earth. So, when
          people ask us whether they can give to support this ministry, we ask
          them to go through this Biblical checklist first:
        </p>{' '}
        <ol>
          <li>
            Are you a born-again child of God? It is a great honor and privilege
            to support the work of God on earth; but that privilege is given
            only to His born-again children (3 John 7).
          </li>
          <li>
            Do you have enough money for your family’s needs? Are you sure that
            your giving will not put your family under any financial strain? You
            must take care of your family’s needs first (See Mark 7:9-13 and 1
            Tim.5:8). Our Father in heaven is very rich and He (like any rich
            earthly father) does not want any of His children to starve or
            suffer in any way, just because they gave Him money for His work.
          </li>
          <li>
            Do you have any large debts to repay? If so, do clear those debts
            first. God wants His children to live restful lives, free from all
            debt. We must first give to Caesar what is his, before we can give
            anything to God, because God does not want us to give Him Caesar’s
            money, or anyone else’s money (Matt.22:21; Rom.13:8). (Note: A
            house-loan is not a “debt” (in the meaning in these verses), because
            the house is an asset that you posses that is equal in value to the
            loan taken. For the same reason, a vehicle-loan is also not a debt –
            provided the vehicle is insured for the value of the loan taken).
          </li>
          <li>
            Do you have a clear conscience? Have you done your best to be
            reconciled with those whom you have hurt in any way? God will not
            accept any offering from anyone who has hurt someone and still not
            apologised to that person (Matt.5:23, 24)
          </li>
          <li>
            Are you giving freely and cheerfully – and not under pressure from
            any man or even from your own conscience? God loves cheerful givers,
            not reluctant givers. He does not want gifts from those who give
            under pressure, or who give in order to fulfill some obligation, or
            who give merely to ease their conscience, or who give in order to
            get some reward from Him in return (2 Cor.9:7).
          </li>
        </ol>
        <p>
          Please bear with us as you go through this checklist. We are seeking
          to follow the example and teaching of our Lord Jesus in financial
          matters. After having carefully considered the above, if you still
          feel you should support this ministry, please write to us by email and
          we can direct you further.
        </p>
      </Prose>
    </Box>

    <Box mt={3} mb={3}>
      <Typography id="contact-us" variant="h3">
        Contact Us
      </Typography>
    </Box>
    <Box mb={3}>
      <Contact />
    </Box>
  </Layout>
);

export default Page;
