import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    fontWeight: 300,
    fontSize: '1.3rem',
    lineHeight: 2.0,
    marginBottom: theme.spacing(8),
  },
}));

const Prose = ({ children }) => {
  const classes = useStyles();

  return (
    <Typography variant="body1" component="div" className={classes.wrapper}>
      {children}
    </Typography>
  );
};

Prose.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Prose;
